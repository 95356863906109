import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Container, Row, Col } from 'react-grid-system';
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl';

import Layout from '../../../components/Layouts/layout';
import SEO from '../../../components/seo';
import Alert from '../../../components/Alert/Alert';
import SubPageNav from '../../../components/SubPageNav/SubPageNav';
import SubPageBottomNav from '../../../components/SubPageBottomNav/SubPageBottomNav';

const HombuyersJourneyStep6Page = () => {
	const intl = useIntl();
	const data = useStaticQuery(graphql`
		query {
			homebuyerImg: file(relativePath: { eq: "montague_porch.jpg" }) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid
					}
				}
			}
		}
	`);

	return (
		<Layout
			view='buyer'
			breadcrumb={{
				text: <FormattedMessage id='buyerNav.dashboard' />,
				path: '/new-home-buyer/dashboard/'
			}}
			header={<FormattedMessage id='buyerNav.steps' />}
		>
			<SEO lang={intl.locale} title={intl.formatMessage({ id: 'buyerNav.step6' })} />
			<Container>
				<Row>
					<Col>
						<SubPageNav
							links={[
								{
									text: <FormattedMessage id='buyerNav.step1' />,
									path: '/new-home-buyer/steps-in-home-buyers-journey/step-1/'
								},
								{
									text: <FormattedMessage id='buyerNav.step2' />,
									path: '/new-home-buyer/steps-in-home-buyers-journey/step-2/'
								},
								{
									text: <FormattedMessage id='buyerNav.step3' />,
									path: '/new-home-buyer/steps-in-home-buyers-journey/step-3/'
								},
								{
									text: <FormattedMessage id='buyerNav.step4' />,
									path: '/new-home-buyer/steps-in-home-buyers-journey/step-4/'
								},
								{
									text: <FormattedMessage id='buyerNav.step5' />,
									path: '/new-home-buyer/steps-in-home-buyers-journey/step-5/'
								},
								{
									text: <FormattedMessage id='buyerNav.step6' />,
									path: '/new-home-buyer/steps-in-home-buyers-journey/step-6/',
									active: true
								}
							]}
						/>
					</Col>
				</Row>

				<Row className='pageRow'>
					<Col md={12}>
						<h2>
							<FormattedMessage id='nav.step' /> 6{' '}
							<span className='thin'>
								{' '}
								<FormattedMessage id='homebuyersStep6.headerA' />
							</span>
						</h2>
						<Alert>
							<p>
								<b>
									<FormattedMessage id='homebuyersStep6.contentA' />
								</b>
								<br />
								<FormattedMessage id='homebuyersStep6.contentB' />{' '}
								<a href='https://www.tarion.com'>www.Tarion.com</a>
							</p>
						</Alert>
					</Col>
				</Row>

				<Row className='pageRow'>
					<Col lg={6} md={12}>
						<p>
							<FormattedMessage id='homebuyersStep6.contentC' />
						</p>

						<p>
							<FormattedMessage id='homebuyersStep6.contentD' />
						</p>

						<p>
							<FormattedMessage id='homebuyersStep6.contentE' />{' '}
							<a href='https://myhome.tarion.com/hop/'>MyHome</a>
							<FormattedMessage id='homebuyersStep6.contentF' />
						</p>

						<p>
							<FormattedMessage id='homebuyersStep6.contentG' />{' '}
							<a href='https://www.tarion.com/'>Tarion</a>{' '}
							<FormattedMessage id='homebuyersStep6.contentH' />{' '}
							<a href='mailto:customerservice@tarion.com'>customerservice@tarion.com</a>.
						</p>
					</Col>

					<Col lg={6} md={12}>
						<Img fluid={data.homebuyerImg.childImageSharp.fluid} alt='' />
					</Col>
				</Row>

				<Row>
					<Col md={12}>
						<SubPageBottomNav
							prevLink={{
								text: <FormattedMessage id='buyerNav.step5' />,
								path: '/new-home-buyer/steps-in-home-buyers-journey/step-5/'
							}}
						/>
					</Col>
				</Row>
			</Container>
		</Layout>
	);
};

export default HombuyersJourneyStep6Page;
